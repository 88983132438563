"use client";
import { debounce } from "lodash";
import { useContext } from "react";
import { DataContext } from "@/app/context/data";
import Link from "next/link";
import styles from "./styles.module.css";
import { IRawPushNotificationV1 } from "@/app/types";
import Asset from "@/app/components/Assets";
import SmNotificationCardCard, {
  SmNotificationCardCardSkeleton,
} from "@/app/components/Cards/SmNotificationCard";
import useScrollToFetchData from "@/app/hooks/useScrollToFetchData";

function NotificationsPopoverBtn({
  hasNotifications,
}: {
  hasNotifications: boolean;
}) {
  if (hasNotifications) {
    return (
      <Link
        href={"/notifications"}
        aria-label="notifications"
        target="_self"
        className={styles.notificationsPopoverBtn}
        prefetch={false}
      >
        <Asset type={"icon"} id={"bell-dot"} size={24} />
      </Link>
    );
  }

  return (
    <Link
      href={"/notifications"}
      aria-label="notifications"
      target="_self"
      className={styles.notificationsPopoverBtn}
      prefetch={false}
    >
      <Asset type={"icon"} id={"bell"} size={24} />
    </Link>
  );
}

function NotificationsPopoverBody({
  notifications,
  hasMoreNotifications,
  loadMoreNotifications,
}: {
  notifications: IRawPushNotificationV1[];
  hasMoreNotifications: boolean;
  loadMoreNotifications: () => void;
}) {
  const debouncedFetchMoreData = debounce(loadMoreNotifications, 2000);
  const observerElement = useScrollToFetchData(debouncedFetchMoreData);

  return (
    <div className={styles.notificationsPopoverBody}>
      {notifications?.length > 0 ? (
        notifications.map((notification) => {
          const { payload_id } = notification;
          return (
            <SmNotificationCardCard key={payload_id} rawData={notification} />
          );
        })
      ) : (
        <p>
          There aren&apos;t any notifications to display. Make sure you enabled
          notifications in your settings.
        </p>
      )}
      {!hasMoreNotifications && notifications?.length > 0 && (
        <p>You&apos;ve reached the end of your notifications</p>
      )}
      {hasMoreNotifications && (
        <div ref={observerElement} className={styles.observerElement}>
          <SmNotificationCardCardSkeleton />
        </div>
      )}
    </div>
  );
}

function NotificationsPopoverBodySkeleton() {
  return (
    <div className={styles.notificationsPopoverBody}>
      {[...Array(10)].map((_, index) => (
        <SmNotificationCardCardSkeleton key={index} />
      ))}
    </div>
  );
}

export default function NotificationsPopover() {
  const {
    notifications,
    notificationsPage,
    loadingNotifications,
    hasMoreNotifications,
    loadMoreNotifications,
  } = useContext(DataContext);
  const hasNotifications = Boolean(notifications?.length > 0);

  return (
    <div className={styles.notificationsPopoverContainer}>
      <NotificationsPopoverBtn hasNotifications={hasNotifications} />
      <div className={styles.notificationsPopoverWrapper}>
        <div className={styles.notificationsPopoverContent}>
          <div className={styles.notificationsPopoverHead}>
            <div>Notifications</div>
            <Link
              href={"/account/notifications"}
              aria-label="notifications"
              target="_self"
              prefetch={false}
            >
              <Asset type={"icon"} id={"settings"} size={18} />
            </Link>
          </div>
          {notificationsPage === 1 && loadingNotifications ? (
            <NotificationsPopoverBodySkeleton />
          ) : (
            <NotificationsPopoverBody
              notifications={notifications}
              hasMoreNotifications={hasMoreNotifications}
              loadMoreNotifications={loadMoreNotifications}
            />
          )}
          <div className={styles.notificationsPopoverFooter}>
            <Link
              href={"/notifications"}
              aria-label="notifications"
              target="_self"
              className={styles.notificationsPopoverLink}
              prefetch={false}
            >
              View all notifications
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
